import { useQuery, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { SliderWithPages } from '/features/buildingBlocks/SliderWithPages'
import { routeMap } from '/routeMap'
import { reportClientError } from '/machinery/reportClientError'

const client = new QueryClient()
export default function NotFoundSliderUniversal() {
  return (
    <QueryClientProvider {...{ client }}>
      <NotFoundSlider />
    </QueryClientProvider>
  )
}

function NotFoundSlider() {
  const { data } = useQuery({
    queryKey: [routeMap.api.v1.notFoundItems()],
    queryFn: async () => fetchNotFoundItems(),
    refetchOnMount: true,
    initialData: {},
    refetchOnWindowFocus: false,
  })

  const { items = [], title = '', backgroundColor } = data
  return <SliderWithPages {...{ items, title, backgroundColor }} />
}

async function fetchNotFoundItems() {
  try {
    const slider = await fetch(routeMap.api.v1.notFoundItems())
      .then(x => x.json())

    return slider.notFoundItems
  } catch (e) {
    reportClientError(e)
    return {}
  }
}
